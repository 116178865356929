var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "1000px", "max-width": "1000px !important" } },
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "사전검토 검토자 현황",
          columns: _vm.grid.columns,
          data: _vm.grid.data,
          gridHeight: _vm.grid.height,
          isExcelDown: false,
          filtering: false,
          columnSetting: false,
          hideBottom: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }